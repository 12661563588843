<template>
  <div>
    <recorder
      ref="speechRecorder"
      type="bot-speaker"
      :is-stop="isStop"
      :msg="msg"
      :info="info"
      :browser-support="browserSupport"
      class="p-1"
      :hide-player="true"
      @start="record"
      @stop="stop"
      @input="
        audio = $event.audio;
        msg = $event.msg
        audioUrl = $event.url;
      "
      @handleBlob="(value) => $emit('handleBlob', value)"
      @recordingState="(value) => (isPlaying = value)"
    />
    <slot :is-playing="isPlaying"
          :stop-function="updateStop"
    />
    <b-modal
      v-if="!browserSupport"
      :visible="true"
      title="Unsupported Browser"
      no-close-on-backdrop
      centered
      ok-only
    >
      <h4 class="text-success">
        We apologize for any inconvenience, but it seems that the current
        browser you are using does not support speech recognition. To utilize
        this feature, we kindly request you to consider using a different
        browser. For an optimal experience, we recommend using either
        <strong class="text-danger">Chrome</strong> or <strong class="text-danger">Edge</strong>, as they provide
        support for speech recognition. Thank you for your understanding.
      </h4>
    </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import Recorder from '@/views/student/problem/compoments/recorder/SpeechRecorder.vue'
import _ from 'lodash'
import { GET_INPUT_LANGUAGE } from '@/const/chatbot'

export default {
  components: {
    Recorder,
    BModal,
  },
  props: {
    autoStop: {
      type: Boolean,
      default: true,
    },
    hidePlayer: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    SpeechRecognition: null,
    SpeechGrammarList: null,
    SpeechRecognitionEvent: null,
    recognition: null,
    msg: '',
    oldMsg: '',
    audio: null,
    audioUrl: null,
    isStop: false,
    isPlaying: false,
    info: null,
    browserSupport: true,
    replaySpeechInterval: [],
  }),
  created() {
    this.initializeSpeechRecognition()
    // this.$root.$on('forceStopRecording', this.stop)
  },
  methods: {
    initializeSpeechRecognition() {
      this.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
      if (!window.SpeechRecognition) {
        if (this.doBrowserReallyDontHaveSpeechSupport()) {
          // browser doesn't support SpeechRecognition API
          this.browserSupport = false
        }
      }
      if (this.browserSupport) {
        this.recognition = new this.SpeechRecognition()
        this.SpeechGrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList
        this.SpeechRecognitionEvent = window.SpeechRecognitionEvent || window.webkitSpeechRecognitionEvent
        const speechRecognitionList = new this.SpeechGrammarList()
        speechRecognitionList.addFromString(this.grammar, 1)
        this.recognition.grammars = speechRecognitionList
        this.recognition.continuous = false
        this.recognition.interimResults = true
        this.recognition.maxAlternatives = 1
      }
    },
    record() {
      this.recognition.lang = GET_INPUT_LANGUAGE[this.$store.getters['appConfig/currentChatbotInputLanguage']] || 'en-US'
      this.recognition.start()
      this.info = 'Please speak to record your response'
      this.isStop = false
      this.isPlaying = true
      this.recognition.onresult = event => {
        const msg = event.results[0][0].transcript
        this.msg = `${this.oldMsg} ${msg}`
        if (this.msg) {
          this.info = 'Please speak to record your response'
        }
      }
      this.recognition.onspeechend = () => {
        // this.recognition.stop()
        if (this.autoStop) {
          this.isStop = true
        } else if (!this.isStop) {
          setTimeout(() => {
            if (!this.isStop) {
              this.recognition.stop()
              this.oldMsg = this.msg
              this.record()
            }
          }, 500)
        }
      }
    },
    stop: _.debounce(function () {
      this.isStop = true
      this.recognition.stop()
      setTimeout(() => {
        this.$emit('text', this.msg)
        this.$emit('audio', this.audio)
        this.$emit('textAudio', {
          text: this.msg,
          audio: this.audioUrl,
        })
        this.oldMsg = ''
        this.msg = ''
      }, 1000)
    }, 700),
    stopAllInterval() {
      this.replaySpeechInterval.forEach(item => {
        clearTimeout(item)
      })
      this.replaySpeechInterval = []
    },
    updateStop() {
      this.stopAllInterval()
      this.isStop = true
      this.isPlaying = false
      this.recognition.stop()
      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        this.$refs.speechRecorder?.deleteAudio()
      }, 100)
    },
  },
}
</script>
